// custom.scss
// Override the primary color variable
$primary: #004AAD;
$light: #FFFFFF;

$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 100%,
  xl: 1200px
);
// Import Bootstrap's functions and variables before overriding
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";


// Import the rest of Bootstrap
@import "~bootstrap/scss/bootstrap";

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&family=Poppins:wght@100;300;400;500;700&display=swap');

/* Appliquer Montserrat avec font-weight 900 au corps de l'application */
body {
  background-color: #004AAD;
  font-family: 'Poppins', sans-serif;
}
h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  color: #fff;
}

h2 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    color: #fff;
}

h3 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    color: #fff;
}

.react-daterange-picker__wrapper {
  border: 0;
}

.react-daterange-picker__inputGroup {
  flex-grow: 0;
}

.react-daterange-picker__clear-button {
  margin-left: auto;
}

.navbar-nav {
  .nav-link {
    font-weight: 500;
    padding: .45rem 1.25rem!important;
    &.active {
      color: #fff;
    }
  }
}